import './App.css';
import React from 'react'
import Particles from "./components/Particles";
import signature from './signature.png'
import { Image } from 'react-bootstrap'
import cv from './LucasGomez-Resume-2022.pdf'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Auth0Provider from '@auth0/auth0-react'

function App() {
  return (
    <div className="App" style={{ backgroundColor: 'black', }}>
      <Breadcrumbs aria-label="breadcrumb" style={{fontSize: 60 , color:'white', display: 'flex', justifyContent: 'center', backgroundColor: 'transparent', position: 'absolute', top: '50%', transform: 'translate( 2.5%,-50%)' }}>
        <Link component={RLink} style={{ color: 'white', fontSize: 55 }} to="/about">
          About me
        </Link>
        <Link component={RLink} style={{ color: 'white', fontSize: 55 }} to="/projects">
          Projects
            </Link>
        <Link style={{ color: 'white', fontSize: 55 }} target="_blank" href={'https://medium.com/@lucasmgomez'} >
          Blogs
            </Link>	
        <Link style={{ color: 'white', fontSize: 55 }} target="_blank" href={cv} >
          Resume
            </Link>
        <Link style={{ color: 'white', fontSize: 55 }} target="_blank" href={'https://github.com/lucasmgomez'} >
          Github
            </Link>
        <Link component={RLink} style={{ color: 'white', fontSize: 55 }} to="/contact">
          Contact me
        </Link>
      </Breadcrumbs>
      <Image style={{ width: 112.88, height: 56.48, position: "absolute", bottom: 20, alignSelf: "flex-end", textAlign: 'right', zIndex: 2 }} src={signature} />
      <Particles />

    </div>
  );
}

export default App;
/* IDEAS:
  https://my-site-pragmatic.herokuapp.com/ | https://git.heroku.com/my-site-pragmatic.git

  Stylin:
    -Randomly pick particle js
    
*/

