import './App.css';
import cv from './LucasGomez-Resume-2022.pdf'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import me from './me.JPG'
import { Image } from 'react-bootstrap'



const Contact = () => (
    <div className='contact'
        style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: 'black',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            backgroundPepeat: 'noRepeat',
        }}>
        <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white', display: 'flex', justifyContent: 'left', backgroundColor: 'transparent', transform: 'translateX(3%)' }}>
            <Link component={RLink} style={{ color: 'white', fontSize: 40 }} to="/">
                Home
            </Link>
            <Link component={RLink} style={{ color: 'white', fontSize: 30 }} to='/projects' >
                Projects
            </Link>
            <Link style={{ color: 'white', fontSize: 30 }} target="_blank" href={'https://medium.com/@lucasmgomez'} >
                Blogs
            </Link>	
            <Link style={{ color: 'white', fontSize: 30 }} href={cv} target="_blank">
                Resume
            </Link>
            <Link style={{ color: 'white', fontSize: 30 }} href={'https://github.com/lucasmgomez'} target="_blank">
                Github
            </Link>
            <Link component={RLink} style={{ color: 'white', fontSize: 30 }} to='/contact' >
                Contact me
            </Link>
            
        </Breadcrumbs>
        <h1 style={{color: 'white', fontSize: 40, textAlign: 'center', padding: '35px'}} >About me</h1>
        <Image style={{  display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%', height: 'auto'}} src={me} />
        <ul style={{fontSize:'20px', color: 'white', justifyContect:'center', textAlign: 'center', listStylePosition:'inside',listStyleType: 'square'}}>
            <li>My name is Lucas Gomez</li>
            <li>4th Year Computer Science and Mathematics at Carleton Universty </li>
            <li>Interested in Artificial Intelligence and the Brain
                <ul style={{fontSize:'20px', color: 'white', justifyContect:'center', textAlign: 'center', listStylePosition:'inside',listStyleType: 'circle'}}>
                    <li>Looking to persue graduate studies in related fields</li>
                </ul>
            </li>
        </ul>
        <p style={{ color: 'white',  textAlign: 'center', padding: '3px'}}> <Link style={{fontWeight: 'bold', color: 'white'}} component={RLink} to='/contact'>Want to chat?</Link></p>
        
    </div>
);

export default Contact;